import EventBus from "eventing-bus";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import React, { Component, Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./index.css";
import { web3 } from "../../store/web3";
import { toFixed } from "../../store/helper";
import { ICO } from "../../store/contract/index.js";
import { DonactionsAbi } from "../../store/contract";
import { setLoader, getDonations } from "../../store/actions/Auth.js";

class TXDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      donationsData: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    await this.getContract();
  }
  async componentWillReceiveProps({ deployedDonations }) {
    if (deployedDonations.length > 0) {
      let result = await this.myEvents(deployedDonations);
      this.setState({ donationsData: result });
    }
  }

  async getContract() {
    let walletAddress = (await web3.currentProvider.enable())[0];
    this.props.getDonations({ walletAddress });
  }

  myEvents = (deployedDonations) => {
    console.log("********* deployedDonations", deployedDonations);
    return new Promise(async (resolve, reject) => {
      try {
        const from = (await web3.currentProvider.enable())[0];

        let newData = [];
        let donationContract = new web3.eth.Contract(DonactionsAbi, deployedDonations.toLowerCase());

        donationContract.events.TokensPurchased({ fromBlock: 0 }, function (error, event) {
          let data = { transactionHash: event['transactionHash'], ...event['returnValues'] }
          newData.push(data);
          resolve(newData);
        });
      } catch (error) {
        EventBus.publish("error", `${error.message}`);
        reject(error);
      }

      // await donationContract.getPastEvents("TokensPurchased", { fromBlock: 0, toBlock: "latest" }, function (error, event) {
      //   let data = { transactionHash: event['transactionHash'], ...event['returnValues'] }
      //   newData.push(data);
      //   resolve(newData);
      // });
    })
  }

  copiedAddress = () => EventBus.publish("success", "Wallet Address Copied");
  copiedAddress = () => EventBus.publish("success", "Wallet Address Copied");
  copiedTransaction = () => EventBus.publish("success", "Transaction Hash Copied");

  render() {
    let { donationsData } = this.state;
    console.log("********* donationsData", donationsData);
    const columns = [
      {
        id: "walletAddress",
        Header: "Donor Address",
        accessor: (donationsData) =>
          donationsData["purchaser"] ? (
            <CopyToClipboard
              text={donationsData["purchaser"]}
              onCopy={this.copiedAddress}
            >
              <a className="public-address">{`${donationsData[
                "purchaser"
              ].substring(0, 8) +
                "....." +
                donationsData["purchaser"].substring(
                  34,
                  donationsData["purchaser"].length
                )}`}</a>
            </CopyToClipboard>
          ) : (
            "-"
          ),
      },
      {
        id: 'donationType',
        Header: 'Donation Type',
        accessor: donationsData => donationsData['donationType'] ? (<a className="public-address">{`${donationsData["donationType"]}`}</a>) : ("-"),
      },
      {
        id: 'donationAmount',
        Header: 'Donation Amount',
        accessor: donationsData => donationsData['donationAmount'] ? (<a className="public-address">{`${toFixed(web3.utils.fromWei(donationsData["donationAmount"], 'ether'), "price")} ${donationsData["donationMethod"]}`}</a>) : ("-"),
      },
      {
        id: 'transactionHash',
        Header: 'Transaction Hash',
        accessor: donationsData => donationsData['transactionHash'] ? (
          <CopyToClipboard
            text={donationsData["transactionHash"]}
            onCopy={this.copiedTransaction}
          >
            <a className="public-address">{`${donationsData[
              "transactionHash"
            ].substring(0, 8) +
              "....."
              }`}</a>
          </CopyToClipboard>
        ) : (
          "-"
        ),
      },
      // {
      //   id: 'amountDonated',
      //   Header: 'Mint Amount',
      //   accessor: donationsData => donationsData['mintAmount'] ? <a className="public-address">{`${donationsData["mintAmount"]}`}</a> : "-",
      //   // accessor: donationsData => donationsData['mintAmount'] ? <a className="public-address">{`${web3.utils.fromWei(donationsData['mintAmount'].toString(), "ether")}`}</a> : "-",
      // },
      // {
      //   id: 'tokenId',
      //   Header: 'Token Id',
      //   accessor: donationsData => donationsData['mintAmount'] ? <a className="public-address">{`${donationsData["mintAmount"]}`}</a> : "-",
      // },
    ];

    return (
      <div className="content">
        <div className="main-container mintedNFT transactionDetailsTax">
          <div className="mb-3">
            <h1 className="main-container-heading headTop text-dark">Tax Transaction Details</h1>
            <p className="descContainerPara">Look for your tax transactions  recorded on the blockchain</p>
          </div>
          <Fragment>
            <div className="main-container-head mb-3">
              <ReactTable
                striped
                className="table"
                data={donationsData}
                resolveData={(donationsData) => donationsData.map((row) => row)}
                columns={columns}
                minRows={20}
                filterable={true}
              />
            </div>
          </Fragment>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader,
  getDonations
};

const mapStateToProps = ({ Auth }) => {
  let { deployedDonations } = Auth;
  return { deployedDonations };
};
export default connect(mapStateToProps, mapDispatchToProps)(TXDetails);
